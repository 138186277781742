import { createSlice } from "@reduxjs/toolkit"; // createSlice ini adalah fungsi yang digunakan untuk membuat reducer dan action secara otomatis
import Cookies from "js-cookie"; // import js-cookie untuk mengambil data dari cookie

const initialState = {
  // definisikan state awal
  isAuthenticated: false, // state awal dari isAuthenticated adalah false
  role: Cookies.get("role") || null, // state awal dari role diambil dari cookie, jika tidak ada di cookie maka null
  accessToken: Cookies.get("accessToken") || null, // state awal dari accessToken diambil dari cookie, jika tidak ada di cookie maka null

  userId: Cookies.get("userId") || null, // state awal dari userId diambil dari cookie, jika tidak ada di cookie maka null
  userName: Cookies.get("userName") || "", // state awal dari userName diambil dari cookie, jika tidak ada di cookie maka null
  fullName: Cookies.get("fullName") || "", // state awal dari fullName diambil dari cookie, jika tidak ada di cookie maka null
  profilePicture: Cookies.get("profilePicture") || null, // state awal dari profilePicture diambil dari cookie, jika tidak ada di cookie maka null
  dob: Cookies.get("dob") || null,
  pob: Cookies.get("pob") || null,
  email: Cookies.get("email") || null,
  nickName: Cookies.get("nickName") || null,
  address: Cookies.get("address") || null,
  gender: Cookies.get("gender") || null,
  phone: Cookies.get("phone") || null,
  groupId: Cookies.get("groupId") || null,
};

const authSlice = createSlice({
  // buat reducer dan action secara otomatis dengan fungsi createSlice
  name: "auth", // nama reducer
  initialState, // state awal (yang sudah didefinisikan diatas)

  // reducer (merupakan fungsi yang digunakan untuk mengubah state)
  reducers: {
    setUserId: (state, action) => {
      // fungsi untuk mengubah state userId
      state.userId = action.payload; // state.userId diubah menjadi action.payload (action.payload adalah data yang didapat dari action useDispatch yang dipanggil di komponen)
    },
    setUserName: (state, action) => {
      // fungsi untuk mengubah state userName
      state.userName = action.payload; // state.userName diubah menjadi action.payload (action.payload adalah data yang didapat dari action useDispatch yang dipanggil di komponen)
    },
    setFullName: (state, action) => {
      // fungsi untuk mengubah state fullName
      state.fullName = action.payload; // state.fullName diubah menjadi action.payload (action.payload adalah data yang didapat dari action useDispatch yang dipanggil di komponen)
    },
    setAuthenticated: (state, action) => {
      // fungsi untuk mengubah state isAuthenticated
      state.isAuthenticated = action.payload; // state.isAuthenticated diubah menjadi action.payload (action.payload adalah data yang didapat dari action useDispatch yang dipanggil di komponen)
    },
    setAccessToken: (state, action) => {
      // fungsi untuk mengubah state accessToken
      state.accessToken = action.payload; // state.accessToken diubah menjadi action.payload (action.payload adalah data yang didapat dari action useDispatch yang dipanggil di komponen)
    },
    setRole: (state, action) => {
      // fungsi untuk mengubah state role
      state.role = action.payload; // state.role diubah menjadi action.payload (action.payload adalah data yang didapat dari action useDispatch yang dipanggil di komponen)
    },
    setProfilePicture: (state, action) => {
      // fungsi untuk mengubah state profilePicture
      state.profilePicture = action.payload; // state.profilePicture diubah menjadi action.payload (action.payload adalah data yang didapat dari action useDispatch yang dipanggil di komponen)
    },
    setDob: (state, action) => {
      // fungsi untuk mengubah state dob
      state.dob = action.payload; // state.dob diubah menjadi action.payload (action.payload adalah data yang didapat dari action useDispatch yang dipanggil di komponen)
    },
    setPob: (state, action) => {
      // fungsi untuk mengubah state pob
      state.pob = action.payload; // state.pob diubah menjadi action.payload (action.payload adalah data yang didapat dari action useDispatch yang dipanggil di komponen)
    },
    setEmail: (state, action) => {
      // fungsi untuk mengubah state email
      state.email = action.payload; // state.email diubah menjadi action.payload (action.payload adalah data yang didapat dari action useDispatch yang dipanggil di komponen)
    },
    setNickName: (state, action) => {
      // fungsi untuk mengubah state nickName
      state.nickName = action.payload; // state.nickName diubah menjadi action.payload (action.payload adalah data yang didapat dari action useDispatch yang dipanggil di komponen)
    },
    setAddress: (state, action) => {
      // fungsi untuk mengubah state address
      state.address = action.payload; // state.address diubah menjadi action.payload (action.payload adalah data yang didapat dari action useDispatch yang dipanggil di komponen)
    },
    setGender: (state, action) => {
      // fungsi untuk mengubah state gender
      state.gender = action.payload;
    },
    setPhone: (state, action) => {
      // fungsi untuk mengubah state phone
      state.phone = action.payload;
    },
    setGroupId: (state, action) => {
      // fungsi untuk mengubah state groupId
      state.groupId = action.payload;
    },
    resetAuth: (state) => {
      // fungsi untuk mengubah state menjadi state awal (initialState) (dipanggil ketika logout)
      state.isAuthenticated = false;
      state.accessToken = null;
      state.userName = null;
    },
  },
});

export const {
  setUserId, // export action setUserId
  setUserName, // export action setUserName
  setRole, // export action setRole
  setFullName, // export action setFullName
  setAuthenticated, // export action setAuthenticated
  setAccessToken, // export action setAccessToken
  setProfilePicture, // export action setProfilePicture
  setDob, // export action setDob
  setPob, // export action setPob
  setEmail, // export action setEmail
  setNickName, // export action setNickName
  setAddress, // export action setAddress
  setGender, // export action setGender
  setPhone, // export action setPhone
  setGroupId, // export action setGroupId
  resetAuth, // export action resetAuth
} = authSlice.actions; // export action yang sudah dibuat diatas agar bisa digunakan di komponen lain

export default authSlice.reducer; // export reducer yang sudah dibuat diatas agar bisa digunakan di store.js
