import Reasadadsct, { useState, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

function EditAmalUsaha() {
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [isCodeValid, setIsCodeValid] = useState(true); // State untuk validasi kode

  const API = process.env.REACT_APP_API_URL;
  const accessToken = useSelector((state) => state.auth.accessToken);
  const id = useParams();
  const [prevData, setPrevData] = useState(null);

  const succes = () => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: `${name} Berhasil Ditambahkan`,
      showConfirmButton: false,
      timer: 1500,
      iconColor: "#007B55",
    });
  };

  const alertError = (name) => {
    Swal.fire({
      position: "center",
      icon: "error",
      title: "Gagal Membuat Amal Usaha!",
      text: `${name}`,
      showConfirmButton: false,
      timer: 1500,
      iconColor: "#C81E1E",
    });
  };

  const navigate = useNavigate();
  useEffect(() => {
    const getPreviousData = async () => {
      try {
        const headers = {
          Authorization: `Bearer ${accessToken} `,
        };
        const response = await axios.get(`${API}/positions/${id.id}`, {
          headers,
        });
        setPrevData(response.data.data.position);
        setName(response.data.data.position.name);
        setCode(response.data.data.position.code);
      } catch (error) {
        console.log(error);
      }
    };
    getPreviousData();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isCodeValid) {
      return; // Tidak melanjutkan jika kode tidak valid
    }
    try {
      const headers = {
        Authorization: `Bearer ${accessToken} `,
      };
      const response = await axios.patch(
        `${API}/positions/${id.id}`,
        {
          name: name,
          code: code,
        },
        {
          headers,
        }
      );
      console.log(response.data);

      succes(`Successfully ${name}`);
      navigate("/amal-usaha");
    } catch (error) {
      alertError(error.response.data.message);
    }
  };

  return (
    <form className="mt-4" onSubmit={(e) => handleSubmit(e)}>
      <div className="mb-6">
        <label
          htmlFor="amal_usaha"
          className="block mb-2 text-sm font-medium text-black"
        >
          Amal Usaha
        </label>
        <input
          onChange={(e) => setName(e.target.value)}
          value={name}
          type="text"
          id="amal_usaha"
          className="bg-white border border-itqan-green-50 text-black text-sm rounded-lg focus:ring-itqan-green-100 focus:border-itqan-green-50 ring-itqan-green-100 block w-full p-2.5"
          placeholder="Nama Amal Usaha"
          required
        />
      </div>
      <div className="mb-6">
        <label
          htmlFor="kode"
          className="block mb-2 text-sm font-medium text-black"
        >
          Kode
        </label>
        <input
          onChange={(e) => {
            const value = e.target.value;
            const hasNumber = /\d/.test(value);

            if (!hasNumber && value.length <= 3) {
              setIsCodeValid(true);
              setCode(value.toUpperCase());
            } else {
              setIsCodeValid(false);
            }
          }}
          value={code}
          type="text"
          id="kode"
          className="bg-white border border-itqan-green-50 text-black text-sm rounded-lg focus:ring-itqan-green-100 focus:border-itqan-green-50 ring-itqan-green-100 block w-full p-2.5 uppercase"
          placeholder="Kode"
          required
        />
        {!isCodeValid && (
          <p className="text-red-500 text-xs font-bold mt-1">
            Kode harus terdiri dari 3 karakter dan tidak mengandung angka
          </p>
        )}
      </div>

      <div className="flex md:gap-12 gap-3 justify-center">
        <NavLink
          to={"/amal-usaha"}
          className="w-55 py-3 bg-white rounded-lg text-black font-bold text-center"
        >
          Kembali
        </NavLink>
        {isCodeValid && name.length > 0 && code.length > 0 && (
          <button className="w-55 py-3 bg-itqan-green-50 rounded-lg text-white font-bold transition-all ease-in-out duration-500">
            Simpan
          </button>
        )}
      </div>
    </form>
  );
}

export default EditAmalUsaha;
