import React, { useEffect, useState, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import AuthRoles from "../Middlewares/AuthRoles";
import SideNavbar from "../components/SideNavbar/SideNavbar";
import Login from "../pages/Login/Login";
import NotFound from "../pages/Error/NotFound";
import EditAmalUsaha from "../pages/AmalUsaha/EditAmalUsaha";

const Dashboard = lazy(() => import("../pages/Dashboard/Dashboard"));
const Laporan = lazy(() => import("../pages/Laporan/Laporan"));
const LaporanAnggota = lazy(() => import("../pages/Laporan/LaporanAnggota"));
const LaporanKelompok = lazy(() => import("../pages/Laporan/LaporanKelompok"));
const DetailLaporanAnggota = lazy(() =>
  import("../pages/Laporan/DetailLaporanAnggota")
);
const Mutabaah = lazy(() => import("../pages/Mutabaah/Mutabaah"));
const EditMutabaah = lazy(() => import("../pages/Mutabaah/EditMutabaah"));
const DetailMateri = lazy(() => import("../pages/Materi/DetailMateri"));
const Kelompok = lazy(() => import("../pages/Kelompok/Kelompok"));
const TambahKelompok = lazy(() => import("../pages/Kelompok/TambahKelompok"));
const DetailKelompok = lazy(() => import("../pages/Kelompok/DetailKelompok"));
const EditKelompok = lazy(() => import("../pages/Kelompok/EditKelompok"));
const Profil = lazy(() => import("../pages/Profil/Profil"));
const Anggota = lazy(() => import("../pages/Anggota/Anggota"));
const TambahAnggota = lazy(() => import("../pages/Anggota/TambahAnggota"));
const AmalUsaha = lazy(() => import("../pages/AmalUsaha/AmalUsaha"));
const TambahAmalUsaha = lazy(() =>
  import("../pages/AmalUsaha/TambahAmalUsaha")
);
const TambahIbadah = lazy(() => import("../pages/Ibadah/TambahIbadah"));
const EditIbadah = lazy(() => import("../pages/Ibadah/EditIbadah"));
const Ibadah = lazy(() => import("../pages/Ibadah/Ibadah"));
const Materi = lazy(() => import("../pages/Materi/Materi"));
const TambahMateri = lazy(() => import("../pages/Materi/TambahMateri"));
const EditMateri = lazy(() => import("../pages/Materi/EditMateri"));
const ExportData = lazy(() => import("../pages/ExportData/ExportData"));

const Router = () => {
  const nickName = useSelector((state) => state.auth.nickName);
  const userName = useSelector((state) => state.auth.userName);
  const [greeting, setGreeting] = useState("");

  useEffect(() => {
    const date = new Date();
    const hour = date.getHours();
    if (hour >= 0 && hour < 12) {
      setGreeting(`Selamat Pagi, ${nickName || userName}`);
    } else if (hour >= 12 && hour < 15) {
      setGreeting(`Selamat Siang, ${nickName || userName}`);
    } else if (hour >= 15 && hour < 18) {
      setGreeting(`Selamat Sore, ${nickName || userName}`);
    } else {
      setGreeting(`Selamat Malam, ${nickName || userName}`);
    }
  }, [nickName]);

  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route
        path="/dashboard"
        element={
          <SideNavbar title={greeting}>
            <Dashboard />
          </SideNavbar>
        }
      />

      <Route
        path="/laporan"
        element={
          <SideNavbar title="Laporan">
            <AuthRoles roles={["Anggota", "Mas'ul", "Murabbi"]}>
              <Laporan />
            </AuthRoles>
          </SideNavbar>
        }
      />

      <Route
        path="/laporan-anggota"
        element={
          <SideNavbar title="Laporan Anggota">
            <AuthRoles roles={["Admin"]}>
              <LaporanAnggota />
            </AuthRoles>
          </SideNavbar>
        }
      />

      <Route
        path="/laporan-anggota/:id"
        element={
          <SideNavbar backButton={true}>
            {/* <AuthRoles roles={["Admin", "Murabbi"]}> */}
            <DetailLaporanAnggota />
            {/* </AuthRoles> */}
          </SideNavbar>
        }
      />

      <Route
        path="/laporan-kelompok/:id"
        element={
          <SideNavbar title="Laporan Kelompok">
            <AuthRoles roles={["Admin"]}>
              <LaporanKelompok />
            </AuthRoles>
          </SideNavbar>
        }
      />

      <Route
        path="/materi"
        element={
          <SideNavbar title="Materi">
            <Materi />
          </SideNavbar>
        }
      />
      <Route
        path="/materi/tambah-materi"
        element={
          <SideNavbar backButton={true}>
            <AuthRoles roles={["Admin", "Murabbi"]}>
              <TambahMateri />
            </AuthRoles>
          </SideNavbar>
        }
      />
      <Route
        path="/materi/edit-materi/:id"
        element={
          <SideNavbar backButton={true}>
            <AuthRoles roles={["Admin", "Murabbi"]}>
              <EditMateri />
            </AuthRoles>
          </SideNavbar>
        }
      />
      <Route
        path="/materi/:id"
        element={
          <SideNavbar backButton={true}>
            <DetailMateri />
          </SideNavbar>
        }
      />
      <Route
        path="/mutabaah-yaumiyah"
        element={
          <SideNavbar title="Mutaba'ah Yaumiyah">
            <AuthRoles roles={["Mas'ul", "Murabbi", "Anggota"]}>
              <Mutabaah />
            </AuthRoles>
          </SideNavbar>
        }
      />
      <Route
        path="/mutabaah-yaumiyah/edit/:id"
        element={
          <SideNavbar title="Edit Mutaba'ah Yaumiyah">
            <AuthRoles roles={["Mas'ul", "Murabbi", "Anggota"]}>
              <EditMutabaah />
            </AuthRoles>
          </SideNavbar>
        }
      />

      <Route
        path="/kelompok"
        element={
          <SideNavbar title="Kelompok">
            <AuthRoles roles={["Admin", "Murabbi", "Mas'ul"]}>
              <Kelompok />
            </AuthRoles>
          </SideNavbar>
        }
      />
      <Route
        path="/kelompok/tambah-kelompok"
        element={
          <SideNavbar title="Kelompok">
            <AuthRoles roles={["Admin"]}>
              <TambahKelompok />
            </AuthRoles>
          </SideNavbar>
        }
      />
      <Route
        path="/kelompok/:id"
        element={
          <SideNavbar backButton={true}>
            <AuthRoles roles={["Murabbi", "Admin", "Mas'ul"]}>
              <DetailKelompok />
            </AuthRoles>
          </SideNavbar>
        }
      />
      <Route
        path="/kelompok/edit-kelompok/:id"
        element={
          <SideNavbar backButton={true}>
            <AuthRoles roles={["Admin"]}>
              <EditKelompok />
            </AuthRoles>
          </SideNavbar>
        }
      />
      <Route
        path="/profil"
        element={
          <SideNavbar backButton={true}>
            <Profil />
          </SideNavbar>
        }
      />
      <Route
        path="/anggota"
        element={
          <SideNavbar title={"Anggota"}>
            <AuthRoles roles={["Admin"]}>
              <Anggota />
            </AuthRoles>
          </SideNavbar>
        }
      />
      <Route
        path="/amal-usaha"
        element={
          <SideNavbar title={"Amal Usaha"}>
            <AuthRoles roles={["Admin"]}>
              <AmalUsaha />
            </AuthRoles>
          </SideNavbar>
        }
      />
      <Route
        path="/amal-usaha/tambah-amal-usaha"
        element={
          <SideNavbar title={"Tambah Amal Usaha"}>
            <AuthRoles roles={["Admin"]}>
              <TambahAmalUsaha />
            </AuthRoles>
          </SideNavbar>
        }
      />
      <Route
        path="/amal-usaha/edit-amal-usaha/:id"
        element={
          <SideNavbar title={"Tambah Amal Usaha"}>
            <AuthRoles roles={["Admin"]}>
              <EditAmalUsaha />
            </AuthRoles>
          </SideNavbar>
        }
      />
      <Route
        path="/anggota/tambah-anggota"
        element={
          <SideNavbar title={"Tambah Anggota"}>
            <AuthRoles roles={["Admin"]}>
              <TambahAnggota />
            </AuthRoles>
          </SideNavbar>
        }
      />
      <Route
        path="/ibadah"
        element={
          <SideNavbar title={"Ibadah"}>
            <AuthRoles roles={["Admin"]}>
              <Ibadah />
            </AuthRoles>
          </SideNavbar>
        }
      />
      <Route
        path="/export-data"
        element={
          <SideNavbar title={"Export Data"}>
            <AuthRoles roles={["Admin"]}>
              <ExportData />
            </AuthRoles>
          </SideNavbar>
        }
      />
      <Route
        path="/ibadah/tambah-ibadah"
        element={
          <SideNavbar title={"Tambah Ibadah"}>
            <AuthRoles roles={["Admin"]}>
              <TambahIbadah />
            </AuthRoles>
          </SideNavbar>
        }
      />
      <Route
        path="/ibadah/edit-ibadah/:id"
        element={
          <SideNavbar title={"Edit Ibadah"}>
            <AuthRoles roles={["Admin"]}>
              <EditIbadah />
            </AuthRoles>
          </SideNavbar>
        }
      />
    </Routes>
  );
};

export default Router;
