/*
  NOTE:
  Semua komponen yang dibungkus oleh komponen AuthMiddleware ini akan di cek apakah user sudah login atau belum
*/

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"; // import useSelector untuk mengambil data dari redux dan useDispatch untuk mengirim data ke redux
import Cookies from "js-cookie"; // import Cookies untuk menggunakan data dari cookies
import {
  setUserId,
  setAuthenticated, // import action creator setAuthenticated dari src\features\authSlice.js (action creator ini digunakan untuk mengirim data ke redux)
  setAccessToken, // import action creator setAccessToken dari src\features\authSlice.js (action creator ini digunakan untuk mengirim data ke redux)
  setUserName, // import action creator setUserName dari src\features\authSlice.js (action creator ini digunakan untuk mengirim data ke redux)
  setRole, // import action creator setRole dari src\features\authSlice.js (action creator ini digunakan untuk mengirim data ke redux)
  setFullName, // import action creator setFullName dari src\features\authSlice.js (action creator ini digunakan untuk mengirim data ke redux)
  setDob,
  setPob,
  setEmail,
  setNickName,
  setAddress,
  setGender,
  setPhone,
  setProfilePicture,
  setGroupId,
} from "../features/authSlice";
import axios from "axios"; // import axios untuk melakukan http request
import { useNavigate } from "react-router-dom"; // import useNavigate untuk melakukan navigasi halaman

const AuthMiddleware = ({ children }) => {
  // props ini untuk menerima data children yang dikirim dari komponen (children merupakan komponen yang akan di render yang bentuknya berupa tag html)
  const navigate = useNavigate(); // membuat object navigate untuk melakukan navigasi halaman
  const dispatch = useDispatch(); // membuat object dispatch untuk mengirim data ke redux
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated); // mengambil data isAuthenticated dari redux dengan useSelector (state.auth.isAuthenticated artinya mengambil data isAuthenticated dari reducer auth yang berada di folder src/features/authSlice.js)
  const API_URL = process.env.REACT_APP_API_URL; // mengambil data REACT_APP_API_URL dari file .env

  // useEffect ini akan dijalankan ketika komponen AuthMiddleware di render
  useEffect(() => {
    const checkAuth = async () => {
      // membuat fungsi checkAuth (dibuat Async karena didalamnya terdapat http request yang membutuhkan waktu untuk mengeksekusi)
      try {
        const refreshToken = Cookies.get("refreshToken") || "{}"; // mengambil data refreshToken dari cookies (jika tidak ada data refreshToken maka akan mengembalikan nilai "{}")
        const response = await axios.post(`${API_URL}/auth/refresh`, {
          // melakukan http request ke endpoint /auth/refresh dengan method POST
          refreshToken, // mengirim data refreshToken ke endpoint /auth/refresh
        });

        if (response?.status === 200) {
          // jika status response 200 (OK) maka akan mengambil data accessToken dari response dan mengirim data accessToken ke redux
          const { accessToken } = response.data.data; // mengambil data accessToken dari response backend
          Cookies.set("accessToken", accessToken); // simpan data accessToken ke cookies
          dispatch(setAccessToken(accessToken)); // simpan juga data accessToken ke redux
          dispatch(setAuthenticated(true)); // mengirim data isAuthenticated ke redux dengan nilai true (artinya user sudah login)
        } else {
          handleAuthenticationFailure(); // jika status response bukan 200 (OK) maka akan menjalankan fungsi handleAuthenticationFailure
        }
      } catch (error) {
        handleAuthenticationFailure(); // jika terjadi error maka akan menjalankan fungsi handleAuthenticationFailure
      }
    };

    // function yang akan dipanggil ketika user belum login atau terjadi error
    const handleAuthenticationFailure = () => {
      dispatch(setAuthenticated(false)); // mengirim data isAuthenticated ke redux dengan nilai false (artinya user belum login)
      navigate("/login"); // navigasi ke halaman /login
    };

    // function untuk mengambil data user dari backend (dijalanjakn ketika user sudah login)
    const getUser = async () => {
      // dibuat Async karena didalamnya terdapat http request yang membutuhkan waktu untuk mengeksekusi
      const accessToken = Cookies.get("accessToken"); // mengambil data accessToken dari cookies
      const headers = { Authorization: `Bearer ${accessToken}` }; // membuat object headers yang berisi data accessToken

      try {
        const response = await axios.get(`${API_URL}/users/profile`, {
          // melakukan http request ke endpoint /users/profile dengan method GET
          headers, // mengirim data headers ke endpoint /users/profile (data headers berisi data accessToken)
        });
        const { user } = response?.data?.data; // mengambil data user dari response backend

        if (user) {
          // jika data user ada maka akan mengirim data user ke redux
          if (user.isAdmin) {
            // jika user.isAdmin bernilai true maka akan mengirim data role ke redux dengan nilai "Admin"
            dispatch(setRole("Admin")); // mengirim data role ke redux dengan nilai "Admin"
          } else if (user.isMurabbi) {
            // jika user.isMurabbi bernilai true maka akan mengirim data role ke redux dengan nilai "Murabbi"
            dispatch(setRole("Murabbi")); // mengirim data role ke redux dengan nilai "Murabbi"
          } else if (user.isMasUl) {
            // jika user.isMasUl bernilai true maka akan mengirim data role ke redux dengan nilai "Mas'ul"
            dispatch(setRole("Mas'ul")); // mengirim data role ke redux dengan nilai "Mas'ul"
          } else {
            // jika user.isAdmin, user.isMurabbi, dan user.isMasUl bernilai false maka akan mengirim data role ke redux dengan nilai "Anggota"
            dispatch(setRole("Anggota")); // mengirim data role ke redux dengan nilai "Anggota"
          }
          dispatch(setUserId(user.id)); // mengirim data userId ke redux
          dispatch(setFullName(user.fullName)); // mengirim data fullName ke redux
          dispatch(setUserName(user.username)); // mengirim data username ke redux
          dispatch(setDob(user.dob)); // mengirim data dob ke redux
          dispatch(setPob(user.pob)); // mengirim data pob ke redux
          dispatch(setEmail(user.email)); // mengirim data email ke redux
          dispatch(setNickName(user.nickname)); // mengirim data nickname ke redux
          dispatch(setAddress(user.address)); // mengirim data address ke redux
          dispatch(setGender(user.gender));
          dispatch(setPhone(user.phone)); // mengirim data phone ke redux
          dispatch(setProfilePicture(user.profilePicture)); // mengirim data profilePicture ke redux
          dispatch(setGroupId(user.assemblyId)); // mengirim data groupId ke redux
        }
      } catch (error) {
        // jika terjadi error maka akan menampilkan error di console
        // alertServer(error.response.data.message);
      }
    };

    checkAuth(); // menjalankan fungsi checkAuth
    getUser(); // menjalankan fungsi getUser (untuk mengambil data user dari backend jika user sudah login)
  }, [dispatch, navigate, isAuthenticated]); // useEffect ini akan dijalankan ketika dispatch atau navigate berubah

  return <>{isAuthenticated && children}</>; // jika user sudah login maka akan merender komponen children (children merupakan komponen yang akan di render yang bentuknya berupa tag html)
};

export default AuthMiddleware;
